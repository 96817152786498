import React, { useState } from 'react'
import {
  Button, DatePicker, Form, Input, Modal,
} from 'antd';
import moment from 'moment';
import { getCookie } from '../../../utils';

export default function PIMedicalLicense({ data }) {
  const userId = getCookie('userName')
  const dateFormat = 'DD-MM-YYYY'
  const columns = [
    {
      label: 'Medical License Number',
      key: 'medical_license_num',
    },
    {
      label: 'Medical License Country Name',
      key: 'medical_license_country_name',
    },
    {
      label: 'Medical License Province',
      key: 'medical_license_province_cn',
    },
    {
      label: 'Medical License Expiry Date',
      key: 'medical_license_expiry_dt',
    },
    {
      label: '',
      key: 'action',
    },
  ]
  const [showDeleteModal, setDeleteModal] = useState(false)
  const [deleteIndex, setDeleteIndex] = useState(undefined)
  const [licenseAlready, setLicenseAlready] = useState(false)
  const [editing, setEditing] = useState({
    isEditing: false,
    editingId: '',
  })

  const [newLicense, setNewLicense] = useState({
    medical_license_num: '',
    medical_license_country_name: '',
    medical_license_province_cn: '',
    medical_license_expiry_dt: undefined,
  })

  const [editLicense, setEditLicense] = useState({
    medical_license_num: '',
    medical_license_country_name: '',
    medical_license_province_cn: '',
    medical_license_expiry_dt: undefined,
  })

  const handleAddNewLicense = () => {
    const temp = data.medicalLicense.filter((itm) => itm.medical_license_num === newLicense.medical_license_num)
    if (temp.length) {
      setLicenseAlready(true)
    }

    if (temp.length === 0) {
      data.setMedicalLicense([...data.medicalLicense,
        {
          medical_license_num: newLicense.medical_license_num ? newLicense.medical_license_num : undefined,
          medical_license_country_name: newLicense.medical_license_country_name ? newLicense.medical_license_country_name : undefined,
          medical_license_province_cn: newLicense.medical_license_province_cn ? newLicense.medical_license_province_cn : undefined,
          medical_license_expiry_dt: newLicense.medical_license_expiry_dt ? moment(newLicense.medical_license_expiry_dt).unix().toString() : undefined,
        }]);
      setNewLicense({
        medical_license_num: '',
        medical_license_country_name: '',
        medical_license_province_cn: '',
        medical_license_expiry_dt: undefined,
      })
      setLicenseAlready(false)
    }
  }

  const onSaveEditClick = () => {
    // here console.log(data issue)

    const duplicate = data.medicalLicense.filter((itm, index) => itm.medical_license_num === editLicense.medical_license_num && editing.editingId !== index)
    if (duplicate.length) {
      setLicenseAlready(true)
    }

    if (duplicate.length === 0) {
      const temp = data.medicalLicense
      const obj = {
        medical_license_num: editLicense.medical_license_num ? editLicense.medical_license_num : undefined,
        medical_license_country_name: editLicense.medical_license_country_name ? editLicense.medical_license_country_name : undefined,
        medical_license_province_cn: editLicense.medical_license_province_cn ? editLicense.medical_license_province_cn : undefined,
        medical_license_expiry_dt: editLicense.medical_license_expiry_dt ? moment(editLicense.medical_license_expiry_dt).unix().toString() : undefined,
      }
      temp[editing.editingId] = obj
      setEditLicense(temp)
      setEditing({ isEditing: false, editingId: '' })
      setLicenseAlready(false)
    }
  }

  const handleLicenseDelete = (index) => {
    const temp = data.medicalLicense
    temp.splice(index, 1)

    data.setMedicalLicense([...temp])
    setDeleteIndex(undefined)
    setDeleteModal(false)
  }
  return (
    <div className={(userId === data.checkUserId && data.checkStatus === 'Send_for_clarification') || data.flag !== 'admin' ? data.deletePi ? 'compliance-page-wrapper disable-pi' : 'compliance-page-wrapper' : 'compliance-page-wrapper disable-pi'}>
      <div className="compliance-page">
        <Form
          layout="vertical"
          name="piform"
          className={(userId === data.checkUserId && data.checkStatus === 'Send_for_clarification') || data.flag !== 'admin'
            ? data.deletePi ? 'disable-pi-form' : null
            : 'disable-pi-form'}
        >
          <div className="license-card">
            <div className="license-table-main">
              <div className="license-table-header">
                {columns.map((type) => (
                  <div className="license-table-header-item">
                    {type.label}
                  </div>
                ))}
              </div>
              {
                data.flag !== 'edit-admin' && (
                  <div className="license-table-body">
                    {data?.medicalLicense?.map((record, index) => {
                      return editing.isEditing && editing.editingId === index ? (
                        <>
                          <div className="license-table-row">
                            <div className="license-table-row-itm">
                              <Input value={editLicense.medical_license_num} onChange={(e) => setEditLicense({ ...editLicense, medical_license_num: e.target.value.trim() })} />
                            </div>
                            <div className="license-table-row-itm">
                              <Input value={editLicense.medical_license_country_name} onChange={(e) => e.target.value !== ' ' && setEditLicense({ ...editLicense, medical_license_country_name: e.target.value })} />
                            </div>
                            <div className="license-table-row-itm">
                              <Input value={editLicense.medical_license_province_cn} onChange={(e) => e.target.value !== ' ' && setEditLicense({ ...editLicense, medical_license_province_cn: e.target.value })} />
                            </div>
                            <div className="license-table-row-itm">
                              <DatePicker value={editLicense.medical_license_expiry_dt} format={dateFormat} onChange={(e) => setEditLicense({ ...editLicense, medical_license_expiry_dt: e })} />
                            </div>
                            <div className="license-table-row-itm">
                              <Button
                                disabled={editLicense.medical_license_num === '' || editLicense.medical_license_country_name === '' || editLicense.medical_license_province_cn === ''}
                                onClick={() => onSaveEditClick()}
                                className="save-btn"
                                title="Save"
                              />
                              <Button
                                onClick={() => {
                                  setEditLicense({
                                    medical_license_num: '',
                                    medical_license_country_name: '',
                                    medical_license_province_cn: '',
                                    medical_license_expiry_dt: undefined,
                                  });
                                  setEditing({ isEditing: false, editingId: '' });
                                  setLicenseAlready(false)
                                }}
                                className="cancel-btn"
                                title="Cancel"
                              />
                            </div>
                          </div>
                          {licenseAlready && <div className="error-msg"> License already exists. Please check. </div>}
                        </>
                      ) : (
                        <div className="license-table-row">
                          <div className="license-table-row-itm">
                            {record.medical_license_num ? record.medical_license_num : '-'}
                          </div>
                          <div className="license-table-row-itm">
                            {record.medical_license_country_name ? record.medical_license_country_name : '-'}
                          </div>
                          <div className="license-table-row-itm">
                            {record.medical_license_province_cn ? record.medical_license_province_cn : '-'}
                          </div>
                          <div className="license-table-row-itm">
                            {record.medical_license_expiry_dt ? new Date(record.medical_license_expiry_dt * 1000).toLocaleDateString() : '-'}
                          </div>
                          <div className="license-table-row-itm">
                            <Button
                              onClick={() => {
                                setDeleteModal(true);
                                setDeleteIndex(index)
                              }}
                              className="delete-btn"
                            />
                            <Button
                              onClick={() => {
                                setEditLicense({
                                  medical_license_num: record.medical_license_num,
                                  medical_license_country_name: record.medical_license_country_name ? record.medical_license_country_name : undefined,
                                  medical_license_province_cn: record.medical_license_province_cn ? record.medical_license_province_cn : undefined,
                                  medical_license_expiry_dt: record.medical_license_expiry_dt ? moment(record.medical_license_expiry_dt * 1000) : undefined,
                                })
                                setEditing({ isEditing: true, editingId: index });
                              }}
                              className="edit-btn"
                              title="Edit"
                            />
                          </div>
                        </div>
                      )
                    })}
                    {
                    !editing.isEditing && (
                      <>
                        <div className="license-table-row">
                          <div className="license-table-row-itm">
                            <Input value={newLicense.medical_license_num} onChange={(e) => setNewLicense({ ...newLicense, medical_license_num: e.target.value.trim() })} />
                          </div>
                          <div className="license-table-row-itm">
                            <Input value={newLicense.medical_license_country_name} onChange={(e) => e.target.value !== ' ' && setNewLicense({ ...newLicense, medical_license_country_name: e.target.value })} />
                          </div>
                          <div className="license-table-row-itm">
                            <Input value={newLicense.medical_license_province_cn} onChange={(e) => e.target.value !== ' ' && setNewLicense({ ...newLicense, medical_license_province_cn: e.target.value })} />
                          </div>
                          <div className="license-table-row-itm">
                            <DatePicker value={newLicense.medical_license_expiry_dt} format={dateFormat} onChange={(e) => setNewLicense({ ...newLicense, medical_license_expiry_dt: e })} />
                          </div>
                          <div className="license-table-row-itm">
                            <Button
                              disabled={newLicense.medical_license_num === '' || newLicense.medical_license_country_name === '' || newLicense.medical_license_province_cn === ''}
                              onClick={() => {
                                if (newLicense.medical_license_num !== '') {
                                  handleAddNewLicense()
                                }
                              }}
                              className="save-btn"
                            />
                            <Button
                              disabled={newLicense.medical_license_num === ''}
                              onClick={() => {
                                setNewLicense({
                                  medical_license_num: '',
                                  medical_license_country_name: '',
                                  medical_license_province_cn: '',
                                  medical_license_expiry_dt: undefined,
                                });
                                setLicenseAlready(false)
                              }}
                              className="cancel-btn"
                            />
                          </div>
                        </div>
                        {licenseAlready && <div className="error-msg"> License already exists. Please check. </div>}
                      </>
                    )
                  }
                  </div>
                )
              }

              {
                data.flag === 'edit-admin' && (
                  <div className="license-table-body">
                    {data?.medicalLicense?.map((record) => {
                      return (
                        <div className="license-table-row">
                          <div className="license-table-row-itm">
                            {record.medical_license_num.new ? record.medical_license_num.new : '-'}
                          </div>
                          <div className="license-table-row-itm">
                            {record.medical_license_country_name.new ? record.medical_license_country_name.new : '-'}
                          </div>
                          <div className="license-table-row-itm">
                            {record.medical_license_province_cn.new ? record.medical_license_province_cn.new : '-'}
                          </div>
                          <div className="license-table-row-itm">
                            {record.medical_license_expiry_dt.new ? new Date(record.medical_license_expiry_dt.new * 1000).toLocaleDateString() : '-'}
                          </div>
                          <div className="license-table-row-itm">
                            <Button
                              // onClick={() => {
                              //   setDeleteModal(true);
                              //   setDeleteIndex(index)
                              // }}
                              className="delete-btn"
                            />
                            <Button
                              // onClick={() => {
                              //   setEditLicense({
                              //     medical_license_num: record.medical_license_num,
                              //     medical_license_country_name: record.medical_license_country_name ? record.medical_license_country_name : undefined,
                              //     medical_license_province_cn: record.medical_license_province_cn ? record.medical_license_province_cn : undefined,
                              //     medical_license_expiry_dt: record.medical_license_expiry_dt ? moment(record.medical_license_expiry_dt * 1000) : undefined,
                              //   })
                              //   setEditing({ isEditing: true, editingId: index });
                              // }}
                              className="edit-btn"
                              title="Edit"
                            />
                          </div>
                        </div>
                      )
                    })}
                    {
                    !editing.isEditing && (
                      <>
                        <div className="license-table-row">
                          <div className="license-table-row-itm">
                            <Input disabled />
                          </div>
                          <div className="license-table-row-itm">
                            <Input disabled />
                          </div>
                          <div className="license-table-row-itm">
                            <Input disabled />
                          </div>
                          <div className="license-table-row-itm">
                            <DatePicker disabled />
                          </div>
                          <div className="license-table-row-itm">
                            <Button
                              disabled
                              className="save-btn"
                            />
                            <Button
                              disabled
                              className="cancel-btn"
                            />
                          </div>
                        </div>
                        {licenseAlready && <div className="error-msg"> License already exists. Please check. </div>}
                      </>
                    )
                  }
                  </div>
                )
              }

            </div>
          </div>
        </Form>
      </div>
      {showDeleteModal && (
        <Modal
          title="Delete Medical License"
          open={showDeleteModal}
          onCancel={() => {
            setDeleteModal(false);
            setDeleteIndex(undefined)
          }}
          destroyOnClose
          footer={null}
          className="showDeleteModal-modal"
        >
          Are you Sure you want to delete this?
          <div className="modal-btn-wrap">
            <Button type="primary" onClick={() => handleLicenseDelete(deleteIndex)}> Yes </Button>
            <Button
              type="primary"
              onClick={() => {
                setDeleteModal(false);
                setDeleteIndex(undefined)
              }}
            > Cancel
            </Button>
          </div>
        </Modal>
      )}
    </div>
  )
}
