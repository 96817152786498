import React, { useEffect, useState } from 'react';
import {
  Button,
  Form, Input, Modal, Radio, Select, Spin,
}
from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import 'antd/dist/antd.css';
import AutoSuggest from './AutoSuggest';
import {
  getautosuggestionCancelAction, getautosuggestionResetAction, getautosuggestvalueAction,
} from '../AddProjectName/logic';
import { getCountryDataAction, getStateDataAction, getStateDataResetAction } from '../GeneralInformation/logic';
import { getCookie, hasPermissionForAction } from '../../../utils';
import OtherResearchAutoSuggest from '../AutoSuggest/OtherResearchAutoSuggest';
import PiAutoSelectForCenterName from '../AutoSuggest/PiAutoSelectForCenterName';
import { getParentDetailsAction, simsIdPresentAction, simsIdPresentResetAction } from '../../components/AddInvestigator/logic';

const PrincipalInvestigator = ({ data }) => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  const dispatch = useDispatch();
  const { Option } = Select;
  const [showHOD, setHODVal] = useState('')
  const getStateData = useSelector((store) => store.getStateData);
  const getCountryData = useSelector((store) => store.getCountryData);
  const [countryList, setCountryList] = useState([])
  const isSimsIdPresent = useSelector((store) => store.isSimsIdPresent)
  const [simsIdError, setSimsIdError] = useState(false)
  const [simsIdAvailable, setSimsIdAvailable] = useState(false)
  const [veevaReadyWarningModal, setVeevaReadyWarningModal] = useState(false)
  const [veevaReadyNoValueWarningModal, setVeevaReadyNoValueWarningModal] = useState(false)
  const [veevaReadyValue, setveevaReadyValue] = useState(data?.principalInvestigator?.veeva_ready_flag_updated?.value)
  const [parentObj, setParentObj] = useState({
    name: '',
    isAlliance: '',
  })
  const [autosuggestData, setAutosuggestData] = useState([{
    id: 0,
    value: '',
    displayDropdown: false,
    displayText: '',
    parent_institution: '',
    is_alliance: '',
  }])
  const [idCounter, setIdCounter] = useState(0)
  const [otherCenter, setOtherCenter] = useState(data?.principalInvestigator?.other_centers?.value);
  const parentDetails = useSelector((store) => store.getParentDetailsResponse);
  const [naNError, setNaNError] = useState(false)
  const [center, setCenter] = useState({
    val: data?.principalInvestigator?.primaryCenter?.value,
    key: data?.principalInvestigator?.primary_organization_id?.value,
    alliance_type: data?.principalInvestigator?.alliance_type?.value,
    alliance_member_id: data?.principalInvestigator?.alliance_member_id?.value,
  });
  const getPiFilterDataResponse = useSelector((store) => store.getPiFilterDataResponse);
  useEffect(() => {
    dispatch(getCountryDataAction())
    setOtherCenter(data?.principalInvestigator?.other_centers?.value)
  }, [])

  useEffect(() => {
    setveevaReadyValue(data?.principalInvestigator?.veeva_ready_flag_updated?.value)
  }, [data?.principalInvestigator])

  useEffect(() => {
    if (parentDetails.flag) {
      if (parentDetails?.data?.data?.length) {
        setParentObj({ name: parentDetails?.data?.data[0].name, isAlliance: parentDetails?.data?.data[0].alliance_type.length })
      }
    }
  }, [JSON.stringify(parentDetails)])

  useEffect(() => {
    setParentObj({ name: '', isAlliance: '' })
    if (Object.keys(center).length) {
      if (center?.key) {
        dispatch(getParentDetailsAction({
          site_id: center.key,
        }))
      }
      if (data.flag === 'fromMainPage' || data.flag === 'savedAnalysis') {
        if (data.principalInvestigator.primaryCenter.key !== center.key) {
          data.setPrincipalInvestigator((oldState) => {
            const temp = {}
            temp.primaryCenter = center.val
            temp.primary_organization_id = center.key
            temp.alliance_type = center.alliance_type ? center.alliance_type : ''
            temp.alliance_member_id = center.alliance_member_id ? center.alliance_member_id : ''
            return { ...oldState, ...temp }
          })
        }
      } else if (data.principalInvestigator.primaryCenter.value !== center.key) {
        data.setPrincipalInvestigator((oldState) => {
          const temp = {}
          temp.primaryCenter = { value: center.val, flag: true }
          temp.primary_organization_id = { value: center.key, flag: true }
          temp.alliance_type = { value: center.alliance_type ? center.alliance_type : '', flag: true }
          temp.alliance_member_id = { value: center.alliance_member_id ? center.alliance_member_id : '', flag: true }
          return { ...oldState, ...temp }
        })
      } else if (data.principalInvestigator.country !== data.primaryCenterCountry) {
        data.setPrincipalInvestigator((oldState) => {
          const temp = {};
          temp.primaryCenterCountry = data.primaryCenterCountry;
          return { ...oldState, ...temp };
        });
      }
    }
  }, [JSON.stringify(center)])
  useEffect(() => {
    if (data?.principalInvestigator?.other_centers?.length) {
      const data1 = data?.principalInvestigator?.other_centers.map((item, index) => {
        setIdCounter(index)
        return ({
          id: index,
          value: item.key,
          displayDropdown: false,
          displayText: item.value,
        })
      })
      setAutosuggestData(data1)
    } else if (otherCenter?.new?.length) {
      const data1 = otherCenter?.new?.map((item, index) => {
        setIdCounter(index)
        return ({
          id: index,
          value: item.id,
          displayDropdown: false,
          displayText: item.name,
        })
      })
      setAutosuggestData(data1)
    }
  }, [])

  useEffect(() => {
    setCenter({
      val: data?.principalInvestigator?.primaryCenter?.value,
      key: data?.principalInvestigator?.primary_organization_id?.value,
      alliance_type: data?.principalInvestigator?.alliance_type?.value,
      alliance_member_id: data?.principalInvestigator?.alliance_member_id?.value,
    })
  }, [data?.principalInvestigator?.primaryCenter])

  useEffect(() => {
    if (data.flag === 'fromMainPage' || data.flag === 'savedAnalysis' || data.flag === 'admin') {
      if (autosuggestData.length) {
        const data1 = autosuggestData.map((item) => {
          return {
            key: item.value,
            value: item.displayText,
          }
        })
        if (data1?.length) {
          const data12 = data1?.filter((item) => item.key !== '')
          data.setPrincipalInvestigator((oldState) => {
            const temp = {}
            temp.other_centers = data12
            return { ...oldState, ...temp }
          })
        } else {
          data.setPrincipalInvestigator((oldState) => {
            const temp = {}
            temp.other_centers = []
            return { ...oldState, ...temp }
          })
        }
      } else {
        data.setPrincipalInvestigator((oldState) => {
          const temp = {}
          temp.other_centers = []
          return { ...oldState, ...temp }
        })
      }
    } else if (autosuggestData?.length) {
      const data1 = autosuggestData?.map((item) => {
        return {
          key: item.value,
        }
      })
      data.setPrincipalInvestigator((oldState) => {
        const temp = {}
        temp.other_centers = { value: data1, flag: true }
        return { ...oldState, ...temp }
      })
    } else {
      data.setPrincipalInvestigator((oldState) => {
        const temp = {}
        temp.other_centers = { value: [], flag: true }
        return { ...oldState, ...temp }
      })
    }
  }, [autosuggestData])

  useEffect(() => {
    if (data.flag === 'fromEditPI') {
      if (data.principalInvestigator?.country?.value) {
        data.setCountryForNpiNumber(data.principalInvestigator.country.value)
        if (data.principalInvestigator?.country?.value === 'United States') {
          data.setWarningFlag(true)
        } else {
          data.setWarningFlag(false)
        }
      }
    } else if (data.principalInvestigator.country) {
      data.setCountryForNpiNumber(data.principalInvestigator.country)
      if (data.principalInvestigator?.country === 'United States') {
        data.setWarningFlag(true)
      } else {
        data.setWarningFlag(false)
      }
    }
  }, [data.principalInvestigator.country])

  useEffect(() => {
    dispatch(getStateDataAction({ country: data?.principalInvestigator?.country?.value }))
  }, [])

  useEffect(() => {
    if (getCountryData.flag) {
      setCountryList(getCountryData?.data?.distinct_countries)
    }
  }, [JSON.stringify(getCountryData)])

  useEffect(() => {
    if (isSimsIdPresent.flag) {
      if (isSimsIdPresent?.data?.isPresent) {
        setSimsIdAvailable(false)
        setSimsIdError(true)
        data.setIsSimsIdVerified(false)
      } else {
        setSimsIdError(false)
        setSimsIdAvailable(true)
        data.setIsSimsIdVerified(true)
      }
      dispatch(simsIdPresentResetAction({}))
    }
  }, [JSON.stringify(isSimsIdPresent)])

  const getValue = (keyName) => {
    if (data.flag === 'fromMainPage' || data.flag === 'savedAnalysis') {
      return data?.principalInvestigator[keyName]
    }
    if (data.flag === 'fromEditPI' && keyName === 'is_head_of_department') {
      if (data?.principalInvestigator[keyName].value === true) {
        return 'Yes'
      }
      return 'No'
    }
    return data?.principalInvestigator[keyName]?.value
  };

  const onStateChange = (e, keyName) => {
    let value
    if (keyName === 'state') {
      value = e || e.target.value
    }
    if (data.flag === 'fromMainPage' || data.flag === 'savedAnalysis') {
      data.setPrincipalInvestigator((oldState) => {
        const temp = {}
        temp[keyName] = value
        return { ...oldState, ...temp }
      })
    } else {
      data.setPrincipalInvestigator((oldState) => {
        let temp = {};
        temp[keyName] = oldState[keyName];
        if (typeof temp[keyName] === 'object') {
          temp[keyName].value = value;
          temp[keyName].flag = true;
        } else {
          temp = {}
          temp[keyName] = { value, flag: true };
        }
        return { ...oldState, ...temp };
      });
    }
  }
  const onPIStateChange = (e, keyName) => {
    let value
    if (keyName === 'titleName' || keyName === 'country' || keyName === 'consent_type' || keyName === 'alliance_type') {
      value = e
    } else {
      value = e.target.value
    }
    if (data.flag === 'fromMainPage' || data.flag === 'savedAnalysis') {
      if (keyName === 'country') {
        data.setCountryForNpiNumber(value)
        dispatch(getStateDataAction({ country: value }))
        dispatch(getStateDataResetAction())
        data.setPrincipalInvestigator((oldState) => {
          const temp = {}
          const temp2 = {}
          const key2 = 'state'
          temp[keyName] = value
          temp2[key2] = ''
          return { ...oldState, ...temp, ...temp2 }
        })
      } else {
        data.setPrincipalInvestigator((oldState) => {
          const temp = {}
          temp[keyName] = value
          return { ...oldState, ...temp }
        })
      }
    } else if (keyName === 'country') {
      data.setCountryForNpiNumber(value)
      dispatch(getStateDataAction({ country: value }))
      dispatch(getStateDataResetAction())
      data.setPrincipalInvestigator((oldState) => {
        let temp = {};
        temp[keyName] = oldState[keyName];
        temp = {}
        const temp2 = {}
        const key = 'state'
        temp[keyName] = { value, flag: true };
        temp2[key] = { value: '', flag: true }
        return { ...oldState, ...temp, ...temp2 };
      });
    } else {
      data.setPrincipalInvestigator((oldState) => {
        let temp = {};
        temp[keyName] = oldState[keyName];
        if (typeof temp[keyName] === 'object') {
          temp[keyName].value = value;
          temp[keyName].flag = true;
        } else {
          temp = {}
          temp[keyName] = { value, flag: true };
        }
        return { ...oldState, ...temp };
      });
    }
  }

  const onIndicationChangeInput = (e) => {
    if (e.trim() && e.length > 2) {
      dispatch(
        getautosuggestvalueAction({
          query: e.trim(),
          type: 'indications',
          dataset: 'queries',
        }),
      )
    }
  }
  const onSpecialityChangeInput = (e) => {
    if (e.trim() && e.length > 2) {
      dispatch(
        getautosuggestvalueAction({
          query: e.trim(),
          type: 'specialities',
          dataset: 'queries',
        }),
      )
    }
  }

  const handleChange = (itm, keyName) => {
    if (data.flag === 'fromMainPage' || data.flag === 'savedAnalysis') {
      data.setPrincipalInvestigator((oldState) => {
        const temp = {}
        temp[keyName] = itm.map((a) => {
          return a.value
        })
        return { ...oldState, ...temp }
      })
    } else {
      data.setPrincipalInvestigator((oldState) => {
        let temp = {};
        temp[keyName] = oldState[keyName];
        if (typeof temp[keyName] === 'object') {
          temp[keyName].value = itm.map((a) => {
            return a.value
          });
          temp[keyName].flag = true;
        } else {
          temp = {}
          temp[keyName] = itm.map((a) => {
            return { value: a.value, flag: true }
          });
        }
        return { ...oldState, ...temp };
      });
    }
  }

  const handleChange1 = (value, keyName) => {
    const degreeValue = value[0]
    if (data.flag === 'fromMainPage' || data.flag === 'savedAnalysis') {
      data.setPrincipalInvestigator((oldState) => {
        const temp = {}
        temp[keyName] = degreeValue
        return { ...oldState, ...temp }
      })
    } else {
      data.setPrincipalInvestigator((oldState) => {
        let temp = {};
        temp[keyName] = oldState[keyName];
        if (typeof temp[keyName] === 'object') {
          temp[keyName].value = degreeValue;
          temp[keyName].flag = true;
        } else {
          temp = {}
          temp[keyName] = { degreeValue, flag: true };
        }
        return { ...oldState, ...temp };
      });
    }
  };

  const onHODChange = (v) => {
    setHODVal(v.target.value)
    if (data.flag === 'fromMainPage' || data.flag === 'savedAnalysis') {
      data.setPrincipalInvestigator((oldState) => {
        const temp = {};
        if (v.target.value === 'Yes') {
          temp.is_head_of_department = true
        } else {
          temp.is_head_of_department = false
        }
        return { ...oldState, ...temp };
      });
    } else {
      data.setPrincipalInvestigator((oldState) => {
        const temp = {};
        const temp1 = {};
        if (v.target.value === 'Yes') {
          temp.is_head_of_department = { value: true, flag: true }
        } else {
          temp.is_head_of_department = { value: false, flag: true }
          temp1.name_of_department = { value: '', flag: true }
        }
        return { ...oldState, ...temp, ...temp1 };
      });
    }
  }
  const onRadioValueChange = (v) => {
    setveevaReadyValue(v.target.value)
    if (v.target.value === 'Yes' && getValue('veeva_ready_flag_updated') === 'Pending review') {
      setVeevaReadyWarningModal(true)
    } else if (v.target.value === 'No' && getValue('veeva_ready_flag_updated') === 'Pending review') {
      setVeevaReadyNoValueWarningModal(true)
    }
  }

  const submitVeevaReadyValue = () => {
    if (data.flag === 'fromMainPage' || data.flag === 'savedAnalysis') {
      data.setPrincipalInvestigator((oldState) => {
        const temp = {};
        temp.veeva_ready_flag_updated = 'Yes'
        return { ...oldState, ...temp };
      });
    } else {
      data.setPrincipalInvestigator((oldState) => {
        const temp = {};
        temp.veeva_ready_flag_updated = { value: 'Yes', flag: true }
        return { ...oldState, ...temp };
      });
    }
    setVeevaReadyWarningModal(false)
  }

  const onPreAwardSurveyOutreach = (v) => {
    if (data.flag === 'fromMainPage' || data.flag === 'savedAnalysis') {
      data.setPrincipalInvestigator((oldState) => {
        const temp = {};
        temp.pre_award_survey_outreach = v.target.value
        return { ...oldState, ...temp };
      });
    } else {
      data.setPrincipalInvestigator((oldState) => {
        const temp = {};
        temp.pre_award_survey_outreach = { value: v.target.value, flag: true }
        return { ...oldState, ...temp };
      });
    }
  }

  const onCenterChangeInput = (e) => {
    if (e instanceof Object && 'val' in e) {
      if (e.val.trim() && e.val.length > 2) {
        dispatch(
          getautosuggestvalueAction({
            query: e.val.trim(),
            type: 'sites',
            filters: JSON.stringify({ parent_flag: false }),
          }),
        )
      }
    } else if (e.trim() && e.length > 2) {
      dispatch(
        getautosuggestvalueAction({
          query: e.trim(),
          type: 'sites',
          filters: JSON.stringify({ parent_flag: false }),
        }),
      )
    }
  }

  const handleSimsIdChange = (e) => {
    setSimsIdError(false)
    setSimsIdAvailable(false)
    if (e === '') {
      data.setIsSimsIdVerified(true)
    } else {
      data.setIsSimsIdVerified(false)
    }
    if (isNaN(e)) {
      setNaNError(true)
      data.setIsSimsIdVerified(false)
    } else {
      setNaNError(false)
      data.setSimsId(e)
    }
  }

  const checkedMandatoryFields = () => {
    if (getValue('piName') && getValue('piLastName') && getValue('email') && getValue('piName') !== '' && getValue('piName').length >= 3 && getValue('piLastName') !== '' && getValue('piLastName').length >= 3 && getValue('email').length && regex.test(getValue('email'))) {
      return true
    }
    return false
  }
  return (
    <div className="investigator-page-wrapper">
      <div className={data.flag === 'admin' ? 'page-wrapper-in-section disable-pi' : 'page-wrapper-in-section'}>
        <div className="main-head-wrap">Principal Investigator</div>
        <Form
          layout="vertical"
          name="piform"
          form={data.principalInvestigatorForm}
          className={data.flag === 'admin' ? 'disable-pi-form' : null}
        >
          <div className="head-wrapper">
            <div className="inner-card">
              <div className="combine-content-from">
                <div className="title-card">
                  <Form.Item
                    label="Title"
                    name="title"
                    className="title-wrapper"
                  >
                    <Select
                      className="input-wrapper"
                      placeholder="Select Title"
                      style={{
                        width: 120,
                      }}
                      value={getValue('titleName')}
                      defaultValue={getValue('titleName')}
                      onChange={(e) => onPIStateChange(e, 'titleName')}
                    >
                      <Option value="Mr">Mr</Option>
                      <Option value="Mrs">Mrs</Option>
                      <Option value="Ms">Ms</Option>
                      <Option value="Dr">Dr</Option>
                      <Option value="Prof">Prof</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="name-card">
                  <Form.Item
                    label="First Name"
                    name="firstname"
                    rules={[{ required: true, message: 'This is a required field' }, { type: 'string', min: 3, message: 'First name should be more than 3 characters' }, { type: 'string', max: 100, message: 'First name should be less than 100 characters' }]}
                    className="name-wrapper"
                  >
                    <Input
                      className="input-wrapper"
                      placeholder="Enter first name"
                      value={getValue('piName')}
                      defaultValue={getValue('piName')}
                      onChange={(e) => onPIStateChange(e, 'piName')}
                    />

                  </Form.Item>
                </div>
                <div className="name-card">
                  <Form.Item
                    label="Middle Name"
                    name="Mname"
                    className="name-wrapper"
                    rules={[{ type: 'string', max: 100, message: 'Middle name should be less than 100 characters' }]}
                  >
                    <Input
                      className="input-wrapper"
                      placeholder="Enter middle name"
                      value={getValue('piMiddleName')}
                      defaultValue={getValue('piMiddleName')}
                      onChange={(e) => onPIStateChange(e, 'piMiddleName')}
                    />

                  </Form.Item>
                </div>
                <div className="name-card">
                  <Form.Item
                    label="Last Name"
                    name="lastname"
                    rules={[{ required: true, message: 'This is a required field' }, { type: 'string', min: 3, message: 'Last name should be more than 3 characters' }, { type: 'string', max: 100, message: 'Last name should be less than 100 characters' }]}
                    className="name-wrapper"
                  >
                    <Input
                      className="input-wrapper"
                      placeholder="Enter last name"
                      value={getValue('piLastName')}
                      defaultValue={getValue('piLastName')}
                      onChange={(e) => onPIStateChange(e, 'piLastName')}
                    />

                  </Form.Item>
                </div>
              </div>
              <div className="npi-card">
                <Form.Item
                  label="NPI number"
                  name="npi"
                  rules={[{
                    pattern: /^(?:[0-9]{10},)*[0-9]{10}$/, max: 10, message: 'No characters allowed or enter valid number',
                  }]}
                  className="npi-wrapper"
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Enter NPI"
                    value={getValue('npi')}
                    defaultValue={getValue('npi')}
                    onChange={(e) => onPIStateChange(e, 'npi')}
                  />
                </Form.Item>

              </div>

              <div className="telephone-card">
                <Form.Item
                  label="Telephone"
                  name="telephone"
                  rules={[{ required: true, message: 'This is a required field' }, { pattern: /^[\d\s\-\\,\\+]+$/, minlength: 5, message: 'No characters allowed or enter valid number' }]}
                  className="telephone-wrapper"
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Enter telephone"
                    value={getValue('telephoneNo')}
                    defaultValue={getValue('telephoneNo')}
                    onChange={(e) => onPIStateChange(e, 'telephoneNo')}
                  />
                </Form.Item>
              </div>

              <div className="mobile-card">
                <Form.Item
                  label="Mobile"
                  name="mobile"
                  rules={[{
                    pattern: /^[0-9\\,\-\\+ ]+$/, min: 10, max: 13, message: 'Enter a valid phone',
                  }]}
                  className="mobile-wrapper "
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Enter mobile"
                    value={getValue('mobileNo')}
                    defaultValue={getValue('mobileNo')}
                    onChange={(e) => onPIStateChange(e, 'mobileNo')}
                  />
                </Form.Item>
              </div>

              <div className="Fax-card">
                <Form.Item
                  label="Fax"
                  name="fax"
                  className="fax-wrapper"
                  rules={[{ pattern: /^(\d+)(,\d+)*$/, message: 'Enter a valid fax' }]}
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Enter fax"
                    value={getValue('faxNo')}
                    defaultValue={getValue('faxNo')}
                    onChange={(e) => onPIStateChange(e, 'faxNo')}
                  />

                </Form.Item>
              </div>

              {(hasPermissionForAction('edit_pi') || data.flag === 'fromMainPage' || data.flag === 'savedAnalysis' || data.flag === 'runAnalysis') && (
                <div className="Email-card">
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'This is a required field' }, { pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Enter a valid email' }]}
                    className="email-wrapper"
                  >
                    <Input
                      className="input-wrapper"
                      placeholder="Enter email"
                      value={getValue('email')}
                      defaultValue={getValue('email')}
                      onChange={(e) => onPIStateChange(e, 'email')}
                    />
                  </Form.Item>
                </div>
              )}

              {(hasPermissionForAction('edit_pi') || data.flag === 'fromMainPage' || data.flag === 'savedAnalysis' || data.flag === 'runAnalysis')
                && (
                  <div className="survey-card">
                    <Form.Item
                      label="Survey email(if different)"
                      name="surveyemail"
                      rules={[{ required: true, message: 'This is a required field' }, { pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Enter a valid email' }]}
                      className="email-wrapper"
                    >
                      <Input
                        className="input-wrapper"
                        placeholder="Enter survey email"
                        value={getValue('surveyEmail')}
                        defaultValue={getValue('surveyEmail')}
                        onChange={(e) => onPIStateChange(e, 'surveyEmail')}
                      />
                    </Form.Item>
                  </div>
                )}

              <div className="other-email-card">
                <Form.Item
                  label="Other email"
                  name="other_email"
                  rules={[{ pattern: /^(|([a-zA-Z0-9_\-\\.]+)@([a-zA-Z0-9_\-\\.]+)\.([a-zA-Z]{2,5}){1,25})+([,.;](([a-zA-Z0-9_\-\\.]+)@([a-zA-Z0-9_\-\\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/, message: 'Enter a valid email' }]}
                  className="email-wrapper"
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Enter other email"
                    value={getValue('other_email')}
                    defaultValue={getValue('other_email')}
                    onChange={(e) => onPIStateChange(e, 'other_email')}
                  />
                </Form.Item>
              </div>

              <div className="therapeutic_interest-card">
                <Form.Item
                  name="therapeuticInterest"
                  label="Therapeutic Interest"
                  className="therapeutic_interest-wrapper"
                >
                  <AutoSuggest
                    mode="multiple"
                    selections={data.principalInvestigator.therapeutic_interest}
                    setValue={data.setPrincipalInvestigator}
                    Value={getValue('therapeutic_interest')}
                    placeholder="Select therapeutic interest"
                    style={{
                      width: '100%',
                    }}
                    handleChange={(e) => handleChange(e, 'therapeutic_interest')}
                    defaultValue={getValue('therapeutic_interest')}
                    fetchOptions={onIndicationChangeInput}
                    flag="indications"
                  />
                </Form.Item>
              </div>

              <div className="primary-speciality-card">
                <Form.Item
                  name="specialties"
                  label="Primary Speciality"
                  rules={[{ required: true }]}
                  className="primary-speciality-wrapper"
                >
                  <AutoSuggest
                    mode="multiple"
                    selections={data.principalInvestigator.specialties}
                    setValue={data.setPrincipalInvestigator}
                    Value={getValue('specialties')}
                    placeholder="Select specialties"
                    style={{
                      width: '100%',
                    }}
                    handleChange={(e) => handleChange(e, 'specialties')}
                    defaultValue={getValue('specialties')}
                    fetchOptions={onSpecialityChangeInput}
                    flag="specialties"
                  />
                </Form.Item>
              </div>

              <div className="secondary-speciality-card">
                <Form.Item
                  label="Secondary Speciality"
                  name="secondaryspeciality"
                  className="secondary-speciality-wrapper"
                >
                  <AutoSuggest
                    mode="multiple"
                    selections={data.principalInvestigator.secondary_specialties}
                    setValue={data.setPrincipalInvestigator}
                    Value={getValue('secondary_specialties')}
                    placeholder="Select secondary specialties"
                    style={{
                      width: '100%',
                    }}
                    handleChange={(e) => handleChange(e, 'secondary_specialties')}
                    defaultValue={getValue('secondary_specialties')}
                    fetchOptions={onSpecialityChangeInput}
                    flag="secondary_specialties"
                  />
                </Form.Item>
              </div>

              {data.flag === 'fromEditPI' && (
                <div className="consent-card">
                  <Form.Item
                    label="Consent type"
                    name="consent-type"
                    className="consent-wrapper"
                  >
                    <Select
                      className="input-wrapper"
                      placeholder="Select Consent type"
                      value={getValue('consent_type')}
                      defaultValue={getValue('consent_type')}
                      onChange={(e) => onPIStateChange(e, 'consent_type')}
                    >
                      <Option value="Yes">Yes</Option>
                      <Option value="No">No</Option>
                    </Select>
                  </Form.Item>
                </div>
              )}

              {data.flag === 'fromEditPI' && (
                <div className="consent-card">
                  <Form.Item
                    label="Consent Text"
                    name="Consent_text"
                    className="consent-wrapper"
                  >
                    <Input
                      className="input-wrapper"
                      placeholder="Enter consent text"
                      value={getValue('consent_text')}
                      defaultValue={getValue('consent_text')}
                      onChange={(e) => onPIStateChange(e, 'consent_text')}
                    />
                  </Form.Item>
                </div>
              )}

              <div className="secondary-speciality-card">
                <Form.Item
                  label="Is Head Of Department?"
                  name="hod"
                  className="secondary-speciality-wrapper"
                >
                  <Radio.Group
                    onChange={(value) => onHODChange(value)}
                    value={getValue('is_head_of_department')}
                    defaultValue={getValue('is_head_of_department')}
                  >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              {(showHOD === 'Yes' || getValue('is_head_of_department') === 'Yes') && (
                <div className="survey-card">
                  <Form.Item
                    label="Department name"
                    name="hod-name"
                    className="email-wrapper"
                  >
                    <Input
                      className="input-wrapper"
                      placeholder="Enter department name"
                      value={getValue('name_of_department')}
                      defaultValue={getValue('name_of_department')}
                      onChange={(e) => onPIStateChange(e, 'name_of_department')}
                    />
                  </Form.Item>
                </div>
              )}

              <div className="secondary-speciality-card">
                <Form.Item
                  label="Degree"
                  className="secondary-speciality-wrapper"
                >
                  <Select
                    mode="tags"
                    placeholder="Select Degree"
                    style={{
                      width: 120,
                    }}
                    onChange={(e) => {
                      if (e.length < 2) {
                        handleChange1(e, 'degree')
                      }
                    }}
                    value={getValue('degree') === '' ? [] : getValue('degree')}
                    defaultValue={getValue('degree') === '' ? [] : getValue('degree')}
                    // onSearch={(e) => handleChange(e, 'degree')}
                    showSearch
                  >
                    {getPiFilterDataResponse && getPiFilterDataResponse?.data?.data?.degree.map((itm) => {
                      return <Option value={itm.value}>{itm.key}</Option>
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className="secondary-speciality-card">
                <Form.Item
                  label="Interested in Pre-award Survey Outreach"
                  name="preaward"
                  className="secondary-speciality-wrapper"
                >
                  <Radio.Group
                    onChange={(value) => onPreAwardSurveyOutreach(value)}
                    value={getValue('pre_award_survey_outreach') === undefined ? 'Yes' : getValue('pre_award_survey_outreach')}
                    defaultValue={getValue('pre_award_survey_outreach') === undefined ? 'Yes' : getValue('pre_award_survey_outreach')}
                  >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>

              <div className="main-head-wrap" style={{ minWidth: '100%' }}>Principal Investigator : Address</div>
              <div className="address-wrapper ">
                <div className="name-card">
                  <Form.Item
                    label="Country"
                    name="country"
                    className="name-wrapper"
                  >
                    <Select
                      showSearch
                      placeholder="Select country"
                      style={{
                        width: 120,
                      }}
                      value={getValue('country')}
                      defaultValue={getValue('country')}
                      onChange={(e) => onPIStateChange(e, 'country')}
                    >
                      {countryList && countryList?.map((itm) => {
                        return <Option value={itm}>{itm}</Option>
                      })}
                    </Select>

                  </Form.Item>
                </div>

                <div className="name-card">
                  <Form.Item
                    label="State"
                    // name="state"
                    className="name-wrapper"
                  >
                    {getStateData?.data?.states?.length !== 1 ? (
                      <Select
                        showSearch
                        placeholder="Select state"
                        style={{
                          width: 120,
                        }}
                        value={getValue('state') !== '' ? getValue('state') : null}
                        onChange={(e) => onStateChange(e, 'state')}
                      >
                        {getStateData.flag && getStateData?.data?.states?.map((itm) => {
                          return <Option key={itm} value={itm}>{itm}</Option>
                        })}
                      </Select>
                    )
                      : (
                        <Input
                          className="input-wrapper"
                          placeholder="Enter state"
                          value={getValue('state')}
                          // defaultValue={getValue('state')}
                          onChange={(e) => onPIStateChange(e, 'state')}
                        />
                      )}

                  </Form.Item>
                </div>

                <div className="name-card">
                  <Form.Item
                    label="City"
                    name="city"
                    className="name-wrapper"
                  >
                    <Input
                      className="input-wrapper"
                      placeholder="Enter city"
                      value={getValue('city')}
                      defaultValue={getValue('city')}
                      onChange={(e) => onPIStateChange(e, 'city')}
                    />

                  </Form.Item>
                </div>

                <div className="name-card">
                  <Form.Item
                    label="Address Line 1"
                    name="Address1"
                    className="name-wrapper"
                  >
                    <Input
                      className="input-wrapper"
                      placeholder="Enter address line 1"
                      value={getValue('address_line1')}
                      defaultValue={getValue('address_line1')}
                      onChange={(e) => onPIStateChange(e, 'address_line1')}
                    />

                  </Form.Item>
                </div>
                <div className="name-card">
                  <Form.Item
                    label="Address Line 2"
                    name="Address2"
                    className="name-wrapper"
                  >
                    <Input
                      className="input-wrapper"
                      placeholder="Enter address line 2"
                      value={getValue('address_line2')}
                      defaultValue={getValue('address_line2')}
                      onChange={(e) => onPIStateChange(e, 'address_line2')}
                    />

                  </Form.Item>
                </div>
                <div className="name-card">
                  <Form.Item
                    label="Postal Code"
                    name="zip"
                    className="name-wrapper"
                    rules={[{ type: 'string', min: 3, message: 'Minimum 3 characters' }]}
                  >
                    <Input
                      className="input-wrapper"
                      placeholder="Enter zip"
                      value={getValue('zip')}
                      defaultValue={getValue('zip')}
                      onChange={(e) => onPIStateChange(e, 'zip')}
                    />

                  </Form.Item>
                </div>
              </div>
              {data.flag === 'fromEditPI' && hasPermissionForAction('edit_sims_id') && data?.principalInvestigator?.pxl_author_sims_ids?.value.length === 0
                && (
                  <div className="add-in-content-id">
                    <div className="add-in-content-id-content">
                      <div className="main-head-wrap" style={{ minWidth: '100%' }}>SIMS IDs</div>
                      <div className="sims-id-card add-in-content-id-content-id-card">
                        <Form.Item
                          className="sims-id-wrapper add-in-content-id-content-id-card-wrapper"
                        >
                          <Input
                            onChange={(e) => { handleSimsIdChange(e.target.value.trim()) }}
                            value={data.simsId}
                            placeholder="Enter SIMS Id"
                          />
                        </Form.Item>
                        <div className="simsid-button add-in-content-id-content-id-card-button">
                          {isSimsIdPresent.loading
                            ? <Spin />
                            : (
                              <Button
                                className="save-btn"
                                disabled={data.simsId === ''}
                                onClick={() => dispatch(simsIdPresentAction({
                                  type: 'investigators',
                                  simsId: data.simsId,
                                }))}
                              />
                            )}
                          <Button
                            className="cancel-btn"
                            disabled={data.simsId === ''}
                            onClick={() => {
                              data.setSimsId('')
                              setSimsIdAvailable(false)
                              data.setIsSimsIdVerified(true)
                              setNaNError(false)
                              setSimsIdError(false)
                            }}
                          />
                        </div>
                      </div>
                      {simsIdError && <div className="sims-id-error add-in-content-id-content-error">SIMS ID entered is already assigned to another profile</div>}
                      {naNError && <div className="sims-id-error add-in-content-id-content-error">SIMS ID should have only numbers</div>}
                      {simsIdAvailable && <div className="sims-id-error add-in-content-id-content-no-error">SIMS ID Available</div>}
                    </div>
                  </div>
                )}

              {data.flag === 'fromEditPI'
                && (
                  <div className="add-in-content-id">
                    <div className="add-in-content-id-content">
                      <div className="main-head-wrap" style={{ minWidth: '100%' }}>Veeva Ready Flag</div>
                      <div className="sims-id-card add-in-content-id-content-id-card">
                        {
                          ((getCookie('permissions').includes('super_admin') || getCookie('permissions').includes('lcor_access')) && checkedMandatoryFields() && getValue('veeva_ready_flag_updated') !== 'Yes' && getValue('veeva_ready_flag_updated') !== '')
                            ? (
                              <Form.Item
                                className="sims-id-wrapper add-in-content-id-content-id-card-wrapper"
                              >
                                <Radio.Group
                                  value={veevaReadyValue}
                                  onChange={(v) => onRadioValueChange(v)}
                                >
                                  <Radio value="Yes">Yes</Radio>
                                  <Radio value="Pending review">Pending Review</Radio>
                                  <Radio value="No">No</Radio>
                                </Radio.Group>
                              </Form.Item>

                            ) : (
                              <Form.Item
                                className="sims-id-wrapper add-in-content-id-content-id-card-wrapper"
                              >
                                <Radio.Group value={getValue('veeva_ready_flag_updated')} disabled>
                                  <Radio value="Yes">Yes</Radio>
                                  <Radio value="Pending review">Pending Review</Radio>
                                  <Radio value="No">No</Radio>
                                </Radio.Group>
                              </Form.Item>
                            )
                        }
                      </div>
                    </div>
                  </div>
                )}

              <div className="main-head-wrap" style={{ minWidth: '100%' }}>Center</div>
              <div className="name-card">
                <Form.Item
                  label="Primary Center Name"
                  name="centername"
                  rules={[{ required: true, message: 'This is a required field' }]}
                  className="name-wrapper primary-center-name"
                >
                  <PiAutoSelectForCenterName
                    apiCall={onCenterChangeInput}
                    apiName="autosuggestdata"
                    selections={getValue('primaryCenter')}
                    setValuee={(val, key, alliance_type, alliance_member_id) => {
                      setCenter({
                        val, key, alliance_type, alliance_member_id,
                      })
                    }}
                    cancelAction={getautosuggestionCancelAction}
                    resetAction={getautosuggestionResetAction}
                    skey="site"
                    IsDropDownOpen={data.IsDropDownOpen}
                    SetDropDownOpen={data.SetDropDownOpen}
                    defaultValue={getValue('primaryCenter')}
                    value={getValue('primaryCenter')}
                    checkFlag={data.flag}
                    setPrimaryCenterCountry={data.setPrimaryCenterCountry}
                    country={data.principalInvestigator.country}
                    setDisabledFlagForEthnicity={data.setDisabledFlagForEthnicity}
                    parentFlag={false}
                  />
                </Form.Item>
              </div>
              <div className="title-card">
                <Form.Item
                  label="Parent Institution"
                  className="title-wrapper other-research-center"
                >
                  {parentDetails.loading ? <Spin /> : (
                    <div className="main-head-wrap-title" style={{ minWidth: '100%' }}>
                      {parentObj?.name ? parentObj?.name : '-'}
                    </div>
                  )}
                </Form.Item>
              </div>
              <div className="title-card">
                <Form.Item
                  label="Is Alliance ?"
                  className="title-wrapper other-research-center"
                >
                  {parentDetails.loading ? <Spin /> : (
                    <div className="main-head-wrap-title" style={{ minWidth: '100%' }}>
                      {parentObj?.isAlliance ? 'Yes' : 'No'}
                    </div>
                  )}
                </Form.Item>
              </div>
              <div className="other-res-center-main">
                <OtherResearchAutoSuggest
                  otherCenter={otherCenter}
                  autosuggestData={autosuggestData}
                  setAutosuggestData={setAutosuggestData}
                  setIdCounter={setIdCounter}
                  idCounter={idCounter}
                />

              </div>
            </div>
          </div>
        </Form>
      </div>
      {
          veevaReadyWarningModal ? (
            <Modal
              title="Confirmation"
              open={veevaReadyWarningModal}
              onOk={() => submitVeevaReadyValue()}
              onCancel={() => {
                setveevaReadyValue('Pending review')
                setVeevaReadyWarningModal(false)
              }}
              cancelButtonProps={{ style: { border: '1px solid #8A0050' } }}
            >
              Are you sure you want to mark this PI as Veeva Ready?
            </Modal>
          ) : null
        }

      {
         veevaReadyNoValueWarningModal ? (
           <Modal
             title="Warning"
             open={veevaReadyNoValueWarningModal}
             onCancel={() => {
               setveevaReadyValue(data?.principalInvestigator?.veeva_ready_flag_updated?.value)
               setVeevaReadyWarningModal(false)
               setVeevaReadyNoValueWarningModal(false)
             }}
             okButtonProps={{ style: { display: 'none' } }}
             cancelButtonProps={{ style: { border: '1px solid #8A0050' } }}
           >
             For a PI that is Pending Review, the Veeva Ready flag cannot be set to No
           </Modal>
         ) : null
        }
    </div>
  );
};

export default PrincipalInvestigator;
